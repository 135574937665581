<template>
  <div>
    <b-modal
      id="assign-windows-to-order"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('AssignTeam')}`"
      size="md"
      hide-footer
      @close="onCancel"
    >
      <template #title>
        <strong style="font-weight: 500; color: black">{{ $t("AssignTeam") }}</strong>
      </template>
      <b-form style="display:flex;flex-direction: column; gap: 10px">
        <b-form-group
          :label="$t('objectName')"
        >
          <vue-select
            v-model="windowIds"
            :options="orderItemIds"
            label="objectName"
            :multiple="true"
            :placeholder="$t('Select')"
          />
        </b-form-group>
        <span
          v-if="windowIds.length == 0 && buttonSubmit"
          style="color:red;"
        > {{ $t('NoObjectFound') }}</span>
        <b-form-group
          :label="$t('Team')"
        >
          <vue-select
            v-model="teamNameId"
            :options="getDropdownTeamName"
            label="teamName"
            :reduce="(e) => e.teamNameId"
            :placeholder="$t('SelectTeam')"
          />
        </b-form-group>
        <span
          v-if="teamNameId == null && buttonSubmit"
          style="color:red;"
        >{{ $t('SelectTeam') }}</span>
        <div class="buttonsEverywhere">
          <button
            type="button"
            class="buttonSubmit"
            variant="none"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"

            style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['orderItemIds'],
  data() {
    return {
      windowIds: [],
      teamNameId: null,
      buttonSubmit: false,
    }
  },
  computed: {
    ...mapGetters(['getDropdownTeamName', 'getUsersActiveInactive']),
  },
  watch: {
    orderItemIds(value) {
      this.windowIds = value
    },
  },
  methods: {
    ...mapActions([]),
    onSubmit() {
      this.buttonSubmit = true
      if (this.windowIds.length == 0 || this.teamNameId == null) {
        return;
      }
      const object = {
        windowItemIds: this.windowIds.map((item) => { return item.windowId }),
        teamNameId: this.teamNameId,
      }
      this.$emit('assignTeams', object)
      this.$refs.modal.hide();
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      if (this.orderItemIds.length > 0) {
        this.windowIds = this.orderItemIds
      } else {
        this.windowIds = []
      }
      this.buttonSubmit = false
      this.teamNameId = null
    },
  },
}

</script>
