<template>
  <div
    style="margin-top: 10px;"
  >
    <section class="main">
      <div
        class="flex"
      >

        <OrdersList
          :create-order="true"
          :class-name="className"
          @sendON="getON"
          @sendId="getId"
          @orderIDName="orderID"
        />
        <ArticleListing
          :order-id="orderNumber"
          :create-order="true"
          :order-ids="orderIds"
          @className="valueOfClassName"
        />

      </div>
    </section>

  </div></template>

<script>
import OrdersList from '@/components/orders/OrdersList.vue'
import ArticleListing from '@/components/orders/ArticleListing.vue'
import { mapGetters } from 'vuex';
import { client } from '../domainConfig'

export default {
  name: 'Home',
  components: {
    OrdersList,
    ArticleListing,
  },
  data() {
    return {
      orderNumber: '',
      productMatObj: {},
      orderIdName: null,
      client: client.clientName,
      orderIds: null,
      className: false,
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    inside(item) {
      this.productMatObj = item
    },
  },
  methods: {
    valueOfClassName() {
      this.className = true
    },
    inside(item) {
      this.productMatObj = item
    },
    getON(id) {
      this.orderNumber = id
    },
    getId(id) {
      this.orderIds = id
    },
    orderID(value) {
      this.orderIdName = value
    },
  },
}
</script>

<style scoped lang="scss">

.flex {
  width: 100%;
}

@media screen and (min-width: 1440px) and (max-width: 1520px) {
  .main{
    padding: 80px 15px 15px 90px;
  }
}

@media screen and (max-width: 1440px){
  .main{
    padding: 75px 15px 15px 80px;
  }
}

@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 90px;
  }
}
 @media screen and (max-width: 1200px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
    justify-content: center;
  }

  .flex {
      flex-direction: column;
  }
 }

 @media screen and (max-width: 780px) {
  .main {
    .flex {
      flex-direction: column;
      .color-listing__wrapper {
        margin-left: 0;
      }
    }
  }
 }
</style>
