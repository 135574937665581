<template>
  <div>
    <b-modal
      id="assign-team-order"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('AssignTeam')}`"
      size="md"
      hide-footer
      @close="onCancel"
    >
      <template #title>
        <strong style="font-weight: 500; color: black">{{ $t("AssignTeam") }}</strong>
      </template>
      <b-form style="display:flex;flex-direction: column; gap: 10px">
        <b-form-group
          :label="$t('Team')"
        >
          <vue-select
            v-model="teamNameId"
            :options="getDropdownTeamName"
            label="teamName"
            :reduce="(e) => e.teamNameId"
            :placeholder="$t('SelectTeam')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('ProcessingUser')"
        >
          <vue-select
            v-model="processingUserId"
            :options="formatedUsers"
            label="fullName"
            :reduce="(e) => e.userId"
            :placeholder="$t('Select')"
          />
        </b-form-group>
        <div class="buttonsEverywhere">
          <button
            type="button"
            class="buttonSubmit"
            variant="none"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"

            style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['windowId', 'orderId'],
  data() {
    return {
      processingUserId: null,
      teamNameId: null,
    }
  },
  computed: {
    ...mapGetters(['getDropdownTeamName', 'getUsersActiveInactive']),
    formatedUsers() {
      return this.getUsersActiveInactive.map((item) => {
        return {
          fullName: `${item.firstName} ${item.lastName}`,
          userId: item.userId,
        }
      })
    },
  },
  watch: {

  },
  methods: {
    ...mapActions([]),
    onSubmit() {
      const object = {
        orderNumber: this.orderId,
        windowItemId: this.windowId,
        teamNameId: this.teamNameId,
        processingUserId: this.processingUserId,
      }
      this.$emit('assignTeam', object)
      this.$refs.modal.hide();
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.teamNameId = null
      this.processingUserId = null
    },
  },
}

</script>
